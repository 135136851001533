.LandingPage {
	text-align: center;
	padding-top: 18vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.instructions {
	position: absolute;
	top: 75%;
	left: 0;
	right: 0;
	margin: auto;
}

/* TEXT ANIMATION */
.text-animation-container {
	position: relative;
	display: flex;
}

.text-animation-container span {
	font-size: 6em;
	color: black;
	font-weight: bold;
	animation: drop 0.4s linear forwards;
}

.text-animation-container span:nth-child(2) {
	animation-delay: 0.2s;
}

.text-animation-container span:nth-child(3) {
	animation-delay: 0.4s;
}

.text-animation-container span:nth-child(4) {
	animation-delay: 0.6s;
}

.text-animation-container span:nth-child(5) {
	animation-delay: 0.8s;
}

.text-animation-container span:nth-child(6) {
	animation-delay: 1s;
}

.text-animation-container span:nth-child(7) {
	animation-delay: 1.2s;
}

.text-animation-container span:nth-child(8) {
	animation-delay: 1.4s;
}

.text-animation-container span:nth-child(9) {
	animation-delay: 1.6s;
}

.text-animation-container span:nth-child(10) {
	animation-delay: 1.8s;
}

@keyframes drop {
	0% {
		transform: translateY(-200px) scaleY(0.9);
	}
	5% {
		opacity: 0.7;
	}
	50% {
		transform: translateY(0px) scaleY(1);
		opacity: 1;
	}
	65% {
		transform: translateY(-17px) scaleY(0.9);
	}
	75% {
		transform: translateY(-22px) scaleY(0.9);
	}
	100% {
		transform: translateY(0px) scaleY(1);
		opacity: 1;
	}
}

/* COFFEE ANIMATION */

@keyframes drop {
	0% {
		transform: translateY(-200px) scaleY(0.9);
	}
	5% {
		opacity: 0.7;
	}
	50% {
		transform: translateY(0px) scaleY(1);
		opacity: 1;
	}
	65% {
		transform: translateY(-17px) scaleY(0.9);
	}
	75% {
		transform: translateY(-22px) scaleY(0.9);
	}
	100% {
		transform: translateY(0px) scaleY(1);
		opacity: 1;
	}
}

/* coffee machine */
.coffee-animation-container {
	max-height: 20vh;
	padding: 0;
	margin: 0;
	width: 350px;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 40%;
}
.upper {
	height: 0;
	width: 180px;
	border-top: 70px solid #735123;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	position: relative;
	margin: 55px auto;
	box-sizing: border-box;
}
.upper:after {
	content: "";
	position: absolute;
	background-color: #3b240e;
	height: 5px;
	width: 185px;
	left: -12.5px;
	top: -70px;
}
.timer {
	content: "";
	position: absolute;
	height: 65px;
	width: 60px;
	background-color: #e06526;
	margin: auto;
	left: 0;
	right: 0;
	top: -65px;
	display: grid;
	place-items: center;
}
.timer:before {
	content: "";
	position: absolute;
	height: 32px;
	width: 32px;
	background-color: #ffffff;
	border-radius: 50%;
}
.timer:after {
	content: "";
	position: absolute;
	background-color: #e06526;
	height: 12px;
	width: 4px;
	border-radius: 4px;
	top: 20px;
}
.upper-btn {
	background-color: #ffffff;
	height: 8px;
	width: 8px;
	position: absolute;
	border-radius: 50%;
	left: 18px;
	bottom: 40px;
}
.upper-btn:before {
	content: "";
	position: absolute;
	background-color: #e06526;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	top: 20px;
	left: -2px;
}
.upper-btn:after {
	content: "";
	position: absolute;
	background-color: #ffffff;
	height: 6px;
	width: 6px;
	border-radius: 50%;
	top: 23px;
	left: 1px;
}
.filter {
	width: 45px;
	height: 16px;
	background: linear-gradient(to right, #ede9d7 70%, #dad1b7 70%);
	position: relative;
	margin: auto;
	bottom: 57px;
	z-index: 5;
	cursor: pointer;
}
.filter-base {
	background-color: #ffffff;
	height: 13px;
	border-radius: 0 0 5px 5px;
	position: relative;
	top: 15px;
	width: 45px;
}
.filter-base:before {
	content: "";
	position: absolute;
	background-color: #ffffff;
	height: 6px;
	width: 12px;
	top: 12px;
	left: 16px;
}
.handle {
	height: 7px;
	border-right: 70px solid #e06526;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	position: relative;
	left: 39px;
	bottom: 16px;
}
.filter:hover .handle {
	animation: spin 6s;
}
@keyframes spin {
	50% {
		transform: translateX(-102px) rotateY(-180deg);
	}
}
.handle:after {
	content: "";
	position: absolute;
	height: 22.5px;
	width: 22.5px;
	border-radius: 50%;
	background-color: #c9561d;
	left: 58px;
	top: -7.5px;
	transform: rotateY(70deg);
}
.coffee {
	height: 30px;
	width: 8px;
	background-color: #e06526;
	border-radius: 0 0 15px 15px;
	position: relative;
	margin: auto;
	bottom: 3px;
	visibility: hidden;
}
.filter:hover .coffee {
	animation: pour 6s 4s;
}
@keyframes pour {
	0% {
		visibility: visible;
	}
	100% {
		transform: translateY(30px);
	}
}
.steam {
	background-color: rgba(204, 204, 204, 0.2);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	position: relative;
	margin: auto;
	top: 50px;
}
.filter:hover .steam {
	animation: rise-steam 4s 5s;
}
@keyframes rise-steam {
	100% {
		transform: translateY(-75px);
	}
}
.steam:before {
	content: "";
	position: absolute;
	background-color: rgba(230, 230, 230, 0.3);
	height: 6px;
	width: 6px;
	bottom: 5px;
	border-radius: 50%;
}
.coffee-drop {
	background-color: #e06526;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	position: relative;
	bottom: 43px;
	left: 18px;
	visibility: hidden;
}
.filter:hover .coffee-drop {
	animation: drop 3s 6.1s;
}
@keyframes drop {
	100% {
		visibility: visible;
		transform: translateY(25px);
	}
}
.back {
	width: 145px;
	background-color: #3b240e;
	height: 140px;
	position: relative;
	margin: auto;
	bottom: 72px;
}
.back:before {
	content: "";
	position: absolute;
	height: 12px;
	width: 155px;
	background-color: #e06526;
	bottom: 0;
	left: -5px;
}
.tray {
	width: 100%;
	height: 15px;
	position: absolute;
	margin: auto;
	bottom: 3px;
	background-image: radial-gradient(#3b240e 5px, transparent 6px);
	background-size: 15px 15px;
	background-position: -2.8px 0;
}
.cup {
	height: 0;
	width: 30px;
	border-top: 70px solid #ede9d7;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	position: relative;
	top: 58px;
	margin: auto;
	z-index: 5;
}
.cup:before {
	content: "";
	position: absolute;
	height: 0;
	width: 6px;
	border-top: 70px solid #dad1b7;
	border-left: 0 solid transparent;
	border-right: 10px solid transparent;
	bottom: 0;
	left: 24px;
}
.cup:after {
	content: "";
	position: absolute;
	height: 4px;
	width: 55px;
	background-color: #ffffff;
	border-radius: 5px;
	bottom: 68px;
	left: -12.5px;
}
.lower {
	height: 30px;
	width: 160px;
	background-color: #735123;
	border-radius: 5px 5px 0 0;
	position: relative;
	margin: auto;
	bottom: 75px;
}
.lower:before {
	content: "";
	position: absolute;
	height: 8px;
	width: 170px;
	background-color: #3b240e;
	bottom: -5px;
	left: -5px;
}
.lower-btn {
	height: 15px;
	width: 9px;
	background-color: #3b240e;
	position: relative;
	left: 13px;
	top: 8px;
}
.lower-btn:before {
	content: "";
	position: absolute;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #e06526;
	bottom: 3px;
	left: 18px;
}
