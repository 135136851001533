
.mug {
	height: 210px;
	width: 220px;
	border: 10px solid #030303;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 30%;
	left: 50%;
	border-radius: 20px 20px 60px 60px;
	background: url("../../../images/coffee-img-1.svg"), url("../../../images/coffee-img-2.svg");
	background-position: 0 0, 0 0;
	background-repeat: repeat-x;
	animation: mug 4s infinite linear;
}
@keyframes mug {
	100% {
		background-position: -100% 0, -100% 0;
	}
}
.mug:before {
	content: "";
	position: absolute;
	height: 80px;
	width: 60px;
	border: 10px solid #030303;
	border-left: none;
	right: -75px;
	top: 30px;
	border-radius: 0 30px 80px 0;
}
.mug:after {
	position: absolute;
	content: "";
	height: 10px;
	width: 300px;
	background-color: #030303;
	left: -45px;
	bottom: -10px;
	border-radius: 10px;
}
.bubble {
	height: 15px;
	width: 15px;
	background-color: #EADDCA;
	border-radius: 50%;
	position: absolute;
	animation: bubbles forwards infinite;
	opacity: 0.6;
}
@keyframes bubbles {
	100% {
		transform: translateY(-150px);
		opacity: 0;
	}
}
.bubble-1 {
	left: 30px;
	bottom: 10px;
	animation-delay: 0.5s;
	animation-duration: 3s;
}
.bubble-2 {
	left: 80px;
	bottom: 35px;
	animation-delay: 1.2s;
	animation-duration: 4s;
}
.bubble-3 {
	left: 140px;
	bottom: 30px;
	animation-duration: 4s;
}
