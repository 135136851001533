.tongue-tip {
	position: absolute;
	bottom: 80%;
	left: 10%;
	width: 80%;
}

.tongue-chart {
	max-width: 40%;
	position: fixed;
	left: 30%;
	z-index: 999;
	display: flex;
	flex-direction: column;
	background-color: white;
	border: 2px solid black;
	border-radius: 5px;
}

.tongue-img {
	max-width: 40%;
	align-self: center;
}

.tongue-btn {
	align-self: flex-end;
	font-size: 1.5rem;
}
